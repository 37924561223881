.waitaminute {
  text-align: center;
  font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto;
}

.rotate {
  animation: rotation 3s infinite linear;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(180deg);
  }
}